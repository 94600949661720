import React, { useState, useContext } from 'react';
import axios from '../api/axios';
import config from '../api/config';

axios.defaults.withCredentials = true;

const InvoiceContext = React.createContext();

export function useInvoice() {
  return useContext(InvoiceContext);
}

export function InvoiceContextProvider({ children }) {
  const [userInvoices, setUserInvoices] = useState([]);

  // Fetch Invoice by user ID
  const getUserInvoices = async () => {
   
    try {
      const response = await axios.get(`/invoices/`);
      setUserInvoices(response.data);
    } catch (error) {
      console.log(`Error fetching Invoices: ${error}`);
    }
  };

  const getInvoiceByID = async (invoiceID) => {
    try {
      const response = await axios.get('/invoices/'+invoiceID);
      console.log(response.data);
    } catch (error) {
      console.log(`Error creating Invoice: ${error}`);
    }
  };

  const value = {
    userInvoices,
    getUserInvoices,
    getInvoiceByID
  };

  return (
    <InvoiceContext.Provider value={value}>
      {children}
    </InvoiceContext.Provider>
  );
}

export default InvoiceContextProvider;

import React, { useRef, useEffect,useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import { useInvoice } from '../context/InvoiceContext';
import "./UserInvoices.css";
export default function UserInvoices() {
  const invoiceRef = useRef();
  const { userInvoices, getUserInvoices } = useInvoice();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: 'Invoice',
  });

  useEffect(() => {
    getUserInvoices();
  }, []);

  const handleViewDetails = (invoiceId) => {
    const invoice = userInvoices.find((inv) => inv._id === invoiceId);
    setSelectedInvoice(invoice);
  };

  const handleBackToList = () => {
    setSelectedInvoice(null);
  };


  if (selectedInvoice) {
    return (
      <div  ref={invoiceRef} className=" container">
        <Row>
        <Col lg="12" md="12" sm="12" sx="12" className="programInformation">
 <button title="Print" onClick={handlePrint} className="btn btn-primary printButton" style={{ marginTop: '20px' }}><FaPrint /></button>
        </Col>
      </Row>
      <Row  className="invoice-details-container">
        <h2>Invoice Details</h2>
        <p><strong>Invoice ID:</strong> {selectedInvoice._id}</p>
        <p><strong>Date:</strong> {new Date(selectedInvoice.subscriptionStartDate).toLocaleDateString()}</p>
        <p><strong>Total Amount:</strong> ${selectedInvoice.totalAmount.toFixed(2)} {selectedInvoice.currency}</p>
        <p><strong>Status:</strong> {selectedInvoice.status}</p>
        <p><strong>Transaction ID:</strong> {selectedInvoice.transactionId || 'N/A'}</p>
        <h4>User Information</h4>
        <p><strong>Name:</strong> {selectedInvoice.payerName}</p>
        <p><strong>Email:</strong> {selectedInvoice.payerEmail}</p>
        <h4>Plan Details</h4>
        <p><strong>Plan Name:</strong> {selectedInvoice.planName}</p>
       
        <p><strong>Valid Till:</strong> {new Date(selectedInvoice.subscriptionEndDate).toLocaleDateString()} </p>
        
        <Button variant="primary" onClick={handleBackToList}>Back to List</Button>
        </Row>
      </div>
    );
  }

  return (
    <div className="user-invoices-container">
      <h2>Your Invoices</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Purchase Date</th>
            <th>Purchased Plan</th>
          </tr>
        </thead>
        <tbody>
          {userInvoices.map((invoice) => (
            <tr key={invoice._id}>
              <td>
                <Button
                  variant="link"
                  onClick={() => handleViewDetails(invoice._id)}
                >
                  {invoice.invoiceNumber || invoice._id}
                </Button>
              </td>
              <td>{new Date(invoice.subscriptionStartDate).toLocaleDateString()}</td>
              <td>{invoice.planName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};


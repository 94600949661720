import React from 'react'
import InvoiceContextProvider from '../context/InvoiceContext'
import UserInvoices from '../components/UserInvoices'

import { FaFileInvoiceDollar} from 'react-icons/fa'
import { Container } from 'react-bootstrap'

export default function CertificationPage() {
  
  return (
   <>
   <InvoiceContextProvider>
   <Container className="appPageContainer">
   <p className="appPageIcon"><FaFileInvoiceDollar/></p>
   <h2 className="appPageTitle"> | Invoices</h2>
    <UserInvoices/>
    </Container>
</InvoiceContextProvider>   
   </>
  )
}

import React from 'react'
import { useFramework } from '../context/FrameworkContext'
import './CompetencyHoverTooltip.css'
import { FaCube } from 'react-icons/fa';
export default function CompetencyHoverTooltip() {
    const {selectedCompetency,tooltipPosition,tooltipIsVisible}=useFramework();
  
  return (
    <div className="toolTipBlock" style={{top:(tooltipPosition.y-180)+"px",left:(tooltipPosition.x+(tooltipPosition.direction==='right'?12:-313))+"px",visibility:tooltipIsVisible}}>
        <div className={(tooltipPosition.direction==='right'?'leftHandle':'rightHandle')}></div>
        <div className="tooltipTitle" style={{backgroundColor:selectedCompetency.color}}><FaCube/>&nbsp;
        {selectedCompetency.name}
          
        </div>

        <div className="tooltipBody">
          <table>
            <tbody>
           
            <tr>
              <td style={{width:"35%"}}><span className="roundedBadge"><b>Type:</b></span></td>
              <td>{selectedCompetency.type}</td>
            </tr>
            <tr>
            <td><span className="roundedBadge"><b >Level:</b></span></td>
              <td>{selectedCompetency.level}</td>
            </tr>
            <tr>
            <td><span className="roundedBadge"><b>Is Required:</b></span></td>
              <td>{selectedCompetency.required}</td>
            </tr>
            <tr>
              <td colspan="2"><span className="roundedBadge"><b>Description:</b></span></td>
            </tr>
            <tr>
              <td colspan="2">{selectedCompetency.description}</td>
            </tr>
           
               </tbody>
          </table>
        </div>      
    </div>
  )
}

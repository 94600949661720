import React , {useRef} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FrameworkContextprovider from '../context/FrameworkContext';
import CourseContextprovider from '../context/CourseContext';
import CertificateContextprovider from '../context/CertificateContext';
import ProgramContextprovider from '../context/ProgramContext';
import SettingContextprovider from '../context/SettingContext';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';

const ReportLayout = ({ children }) => {
  const reportRef = useRef();
   
    const handlePrint = useReactToPrint({
      content: () => reportRef.current,
      documentTitle: 'Report',
    });
  return (
    <div className="wrapper" >
      <Header />
      <div>
      <button className='btn btn-primary printButton'  onClick={handlePrint}><FaPrint/></button>
      </div>
      <div className="pageContainer" ref={reportRef}>
       
      <CertificateContextprovider>
      <CourseContextprovider>
      <ProgramContextprovider>
      <FrameworkContextprovider>
        <SettingContextprovider>

        {children}

        </SettingContextprovider>
      </FrameworkContextprovider>
      </ProgramContextprovider>
      </CourseContextprovider>
      </CertificateContextprovider>
      </div>
      <Footer />
    </div>
  );
};

export default ReportLayout;
import React, { useContext, useState, useEffect } from "react";
import axios from '../api/axios';
import { useAuth } from "./AuthContext";
import config from "../api/config";
import { v4 as uuid } from 'uuid'; 

axios.defaults.withCredentials = true;
const FrameworkContext = React.createContext();

export function useFramework() {
    return useContext(FrameworkContext);
}

export function FrameworkContextprovider({ children }) {

    const [userFrameworks, setUserFrameworks] = useState([]);
    const [selectedFrameworkID, setSelectedFrameworkID] = useState();
    const [framework, setFramework] = useState({ frameworkName: "", frameworkDescription: "", roles: [] });
    const [selectedCompetency, setSelectedCompetency] = useState({});
    const [tooltipPosition, setTooltipPosition] = useState({});
    const [tooltipIsVisible, setTooltipIsVisible] = useState('hidden');
    const [coloredBy, setColoredBy] = useState('type');
    const [editModalIsVisible, setEditModalIsVisible] = useState(false);
    const [message, setMessage] = useState({ header: "", text: "", variant: "", buttons: [{ text: "" }], isVisible: false });

    const competencySettings = {
        type: [
            { label: 'Core Competencies', color: '#4C8CAC' }, // Clear Pastel Teal
            { label: 'Technical or Functional Competencies', color: '#FFD166' }, // Bright Pastel Yellow
            { label: 'Leadership Competencies', color: '#94B1C9' }, // Soft Blue-Gray
            { label: 'Management Competencies', color: '#C483B6' }, // Light Mauve
            { label: 'Interpersonal Competencies', color: '#FFBE5E' }, // Soft Tangerine
            { label: 'Analytical Competencies', color: '#6FA9D1' }, // Sky Blue
            { label: 'Digital Competencies', color: '#FF8F66' }, // Warm Coral
            { label: 'Business Competencies', color: '#E1B57E' }, // Soft Amber
            { label: 'Personal Effectiveness Competencies', color: '#71C2C8' }, // Aquamarine
            { label: 'Ethical and Legal Competencies', color: '#D77F9F' }, // Soft Pink Rose
            { label: 'Safety Competencies', color: '#6C9BC1' }, // Cobalt Pastel
            { label: 'Environmental Competencies', color: '#D89D64' }, // Warm Sand
            { label: 'Communication and Language Competencies', color: '#539FC4' }, // Ocean Blue
            { label: 'Customer Service Competencies', color: '#FFAE94' }, // Pastel Salmon
            { label: 'Sales and Marketing Competencies', color: '#BCC5D3' }, // Cool Gray
            { label: 'Innovation Competencies', color: '#E67C8C' }, // Bright Coral Pink
            { label: 'Learning and Development Competencies', color: '#73D1C8' }, // Vibrant Aqua
            { label: 'Organizational Competencies', color: '#D8A27B' }, // Toasted Peach
            { label: 'Research Competencies', color: '#F1A364' }, // Warm Orange
            { label: 'Supply Chain and Logistics Competencies', color: '#8CB29B' }, // Pastel Green
            { label: 'Human Resources Competencies', color: '#B082A9' }, // Lavender Plum
            { label: 'Quality Management Competencies', color: '#C6DC87' }, // Bright Lime Green
            { label: 'Administrative Competencies', color: '#DAB49E' }, // Warm Beige
            { label: 'Legal Competencies', color: '#E8C585' }, // Honey Gold
        ],
        level: [
            { label: 'Novice', color: '#539FC4' }, // Ocean Blue
            { label: 'Intermediate', color: '#FFD166' }, // Bright Pastel Yellow
            { label: 'Proficient', color: '#FF8F66' }, // Warm Coral
            { label: 'Advanced', color: '#6FA9D1' }, // Sky Blue
            { label: 'Expert', color: '#4C8CAC' }, // Clear Pastel Teal
        ],
        required: [
            { label: 'Must have', color: '#FFAE94' }, // Pastel Salmon
            { label: 'Preferable to have', color: '#4C8CAC' }, // Clear Pastel Teal
        ],
    };
    
    
    



    const getFrameworks = async () => {
        try {
            const res = await axios.get('/frameworks/', { withCredentials: true });
            setUserFrameworks(res.data);
            const selectedFrameIndex = await res.data?.findIndex((framework) => framework._id === selectedFrameworkID);
            if (selectedFrameIndex > -1) {
                setFramework(res.data[selectedFrameIndex]);
            } else {
                setFramework(res.data[0]);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

 
    



    const getSingleFramework = async (frameworkID) => {
        await axios.get('/frameworks/' + frameworkID)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const addEmptyFramework = () => {
        let emptyFramework = { frameworkName: "the framework name placeholder", frameworkDescription: 'the framework description placeholder', roles: [] };
        axios.post('/frameworks/', emptyFramework).then(response => {
            getFrameworks();
            setSelectedFrameworkID(response.data._id);
        });
    }

    const updateFramework = async (frameworkID) => {
        console.log(framework);
        await axios.put('/frameworks/' + frameworkID, { ...framework })
            .then(response => {
                console.log(response);
                getFrameworks();
            })
            .catch(error => {
                console.log(error);
            });
    }

    const removeFramework = async (frameworkID) => {
        await axios.delete('/frameworks/' + frameworkID);
        getFrameworks();
    }

    const addEmptyRole = () => {
        let emptyRole = { _id: uuid(), name: "", description: '', functions: [] };
        let updatedFramework = { frameworkName: framework.name, frameworkDescription: framework.description, roles: [...(framework.roles), emptyRole] };
        setFramework(updatedFramework);
    }

    const removeRole = (roleID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            updatedFramework.roles.splice(toDeleteRoleIndex, 1);
            console.log(roleID + " has been deleted");
            setFramework(updatedFramework);
        }
    }

    const addEmptyFunction = (roleID) => {
        let emptyFunction = { _id: uuid(), name: "", description: '', competencies: [] };
        let updatedFramework = { ...framework };
        let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toChangeRoleIndex > -1) {
            updatedFramework.roles[toChangeRoleIndex].functions.push(emptyFunction);
            setFramework(updatedFramework);
        } else {
            console.log('did not find the role');
        }
    }

    const removeFunction = (roleID, functionID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            let toDeleteFunctionIndex = updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toDeleteFunctionIndex > -1) {
                updatedFramework.roles[toDeleteRoleIndex].functions.splice(toDeleteFunctionIndex, 1);
                setFramework(updatedFramework);
            }
        }
    }

    const addEmptyCompetency = (roleID, functionID) => {
        let emptyCompetency = {
            _id: uuid(), name: "", description: "",
            level: competencySettings.level[0].label,
            type: competencySettings.type[0].label,
            required: competencySettings.required[0].label,
            requirementof: []
        };
        let updatedFramework = { ...framework };
        let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toChangeRoleIndex > -1) {
            let toChangeFunctionIndex = updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toChangeFunctionIndex > -1) {
                updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.push(emptyCompetency);
                setFramework(updatedFramework);
            }
        }
    }

    const removeCompetency = (roleID, functionID, competencyID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            let toDeleteFunctionIndex = updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toDeleteFunctionIndex > -1) {
                let toDeleteCompetencyIndex = updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.findIndex((theCompetency) => theCompetency._id === competencyID);
                if (toDeleteCompetencyIndex > -1) {
                    updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.splice(toDeleteCompetencyIndex, 1);
                    setFramework(updatedFramework);
                }
            }
        }
    }

    const toggleEditModal = () => {
        setEditModalIsVisible(!editModalIsVisible);
    }

    const changeSingleValue = (fieldName, fieldValue, roleID = '*', functionID = '*', competencyID = '*') => {
        let updatedFramework = { ...framework };
        if (roleID !== '*') {
            let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
            if (functionID !== '*') {
                let toChangeFunctionIndex = updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
                if (competencyID !== '*') {
                    let toChangeCompetencyIndex = updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.findIndex((competency) => competency._id === competencyID);
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies[toChangeCompetencyIndex][fieldName] = fieldValue;
                } else {
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex][fieldName] = fieldValue;
                }
            } else {
                updatedFramework.roles[toChangeRoleIndex][fieldName] = fieldValue;
            }
        }
        setFramework(updatedFramework);
    }

    // Function to generate a shareable token for a framework
    const generateShareToken = async (frameworkID) => {
        try {
            const response = await axios.post('/utilities/generate-share-token', { idToTokenize: frameworkID });
            return response.data.token;
        } catch (error) {
            console.error('Error generating share token:', error);
            return null;
        }
    };
    const getSharedFramework = async (token) => {
        try {
            // Use GET and pass the token as a query parameter
            const response = await axios.get(`/frameworks/get-shared-framework?token=${token}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching shared framework:', error);
            return null;
        }
    };
    

    const value = {
        framework,
        selectedCompetency,
        setSelectedCompetency,
        setTooltipPosition,
        tooltipPosition,
        tooltipIsVisible,
        setTooltipIsVisible,
        addEmptyRole,
        removeRole,
        addEmptyFunction,
        addEmptyCompetency,
        setFramework,
        changeSingleValue,
        competencySettings,
        coloredBy,
        setColoredBy,
        editModalIsVisible,
        toggleEditModal,
        setEditModalIsVisible,
        message,
        setMessage,
        removeFunction,
        removeCompetency,
        userFrameworks,
        setUserFrameworks,
        selectedFrameworkID,
        setSelectedFrameworkID,
        addEmptyFramework,
        getFrameworks,
        removeFramework,
        updateFramework,
        generateShareToken, // Added the new function
        getSharedFramework
    };

    return (
        <FrameworkContext.Provider value={value}>
            {children}
        </FrameworkContext.Provider>
    )
}

export default FrameworkContextprovider;

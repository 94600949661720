import React, { useMemo } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';
import config from '../api/config';

const PayPalPaymentComponent = ({ userId, purchasedPlan, onSuccess }) => {
  // Calculate yearly cost dynamically using useMemo
  const yearlyCostValue = useMemo(() => {
    const cost = parseFloat(purchasedPlan?.cost || 0);
    return cost > 0 ? (12 * cost).toFixed(2) : "0.00";
  }, [purchasedPlan]);

  const handlePaymentSuccess = async (paymentDetails) => {
    try {
      // Notify parent of successful payment
      onSuccess(paymentDetails);
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };

  return (
    <PayPalScriptProvider options={{ "client-id": "AcWesfvyAYASLC8DArXtu74bFHkzj1W3dYq6AISZswkrEmrxGkCG7AIbVVpmGzy5OSK_7F-VAGR6ZIji", "currency": "CAD" }}>
  <PayPalButtons
          fundingSource="card"
          createOrder={(data, actions) => {
            if (!yearlyCostValue || isNaN(parseFloat(yearlyCostValue))) {
              alert('Invalid plan cost');
              return Promise.reject(new Error('Invalid plan cost'));
            }
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: yearlyCostValue, // Dynamically updated value
                    currency: "CAD",
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              handlePaymentSuccess(details);
            });
          }}
          onError={(error) => {
            console.error('PayPal button error:', error);
            alert('Payment failed. Please try again.');
          }}
        />
      </PayPalScriptProvider>
    );
  };

  export default PayPalPaymentComponent;
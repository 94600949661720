import{React} from 'react'
import {Routes,Route} from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import FrameworksManagementPage from './pages/FrameworksManagementPage';
import ProgramsManagementPage from './pages/ProgramsManagementPage';
import CoursesManagementPage from './pages/CoursesManagementPage';
import SettingsPage from './pages/SettingsPage';
import HelpPage from './pages/HelpPage';
import CertificationPage from './pages/CertificationPage';

import PricingAndPlansPage from './pages/PricingAndPlansPage';
import LearningResourcesPage from './pages/LearningResourcesPage';
import ContactUsPage from './pages/ContactUsPage';
import RequireAuth from './components/RequireAuth';
import AccountActivationPage from './pages/AccountActivationPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ReportsPage from './pages/ReportsPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import CookiesPolicyPage from './pages/CookiesPolicyPage';

import ChangePasswordPage from './pages/ChangePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage'; 
import ProfileManagerPage from  './pages/ProfileManagerPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ActivationRequiredPage from './pages/ActivationRequiredPage';

import UserHistoriesPage from './pages/UserHistoriesPage';
import UserInvoicesPage from './pages/UserInvoicesPage';

import SingleFrameworkReport from './reports/SingleFrameworkReport';

import MainLayout from './layouts/MainLayout';
import ReportLayout from './layouts/ReportLayout';
//****************************************************** */
import AllFrameworksReport from './reports/AllFrameworksReport';
import AllProgramsReport from './reports/AllProgramsReport';
import AllCoursesReport from './reports/AllCoursesReport';
import AllCertificatesReport from './reports/AllCertificatesReport';
import EmbedFrameworkPage from './pages/EmbedFrameworkPage';
import PurchasePlanPage from './pages/PurchasePlanPage';
import CoursePrerequisiteReport from './reports/CoursePrerequisitesReport';


export default function AppRoutes() {
  
  return (
    <Routes>
      
      {/* Public Routes */}

        <Route exact path="/" element={<MainLayout><HomePage/></MainLayout>} /> 
        <Route path="/signup" element={<MainLayout><SignUpPage /></MainLayout>} />
        <Route path="/signin" element={<MainLayout><SignInPage /></MainLayout>} />
        
        <Route path="/pricing-and-plans" element={<MainLayout><PricingAndPlansPage /></MainLayout>} />
        <Route path="/home" element={<MainLayout><HomePage /></MainLayout>} />
        <Route path="/learning-resources" element={<MainLayout><LearningResourcesPage /></MainLayout>} />
        <Route path="/contact-us" element={<MainLayout><ContactUsPage /></MainLayout>} />
        <Route path="/activation/:token" element={<MainLayout><AccountActivationPage /></MainLayout>} />
        <Route path="/privacy-policy" element={<MainLayout><PrivacyPolicyPage /></MainLayout>} />
        <Route path="/terms-of-service" element={<MainLayout><TermsOfServicePage /></MainLayout>} />
        <Route path="/cookies-policy" element={<MainLayout><CookiesPolicyPage /></MainLayout>} />
        <Route path="/activation-required" element={<MainLayout><ActivationRequiredPage /></MainLayout>} />
        <Route path="/forgot-password" element={<MainLayout><ForgotPasswordPage /></MainLayout>} />
        <Route path="/reset-password/:token" element={<MainLayout><ResetPasswordPage /></MainLayout>} />
        <Route path="/reset-password/:token" element={<MainLayout><ResetPasswordPage /></MainLayout>} />
        <Route path="/shared/framework/:token" element={<EmbedFrameworkPage/>} />  
        <Route path="/help" element={<MainLayout><HelpPage /></MainLayout>} />
      {/* User Routes */}
          <Route path="/frameworks/:frameworkID" element={<RequireAuth ><MainLayout><FrameworksManagementPage /></MainLayout></RequireAuth>} /> 
          <Route path="/frameworks" element={<RequireAuth ><MainLayout><FrameworksManagementPage /></MainLayout></RequireAuth>} />    
          <Route path="/programs" element={<RequireAuth><MainLayout><ProgramsManagementPage /></MainLayout></RequireAuth>} />
          <Route path="/programs/:programID" element={<RequireAuth><MainLayout><ProgramsManagementPage /></MainLayout></RequireAuth>} />
          <Route path="/settings" element={<RequireAuth><MainLayout><SettingsPage /></MainLayout></RequireAuth>} />
          <Route path="/courses" element={<RequireAuth><MainLayout><CoursesManagementPage /></MainLayout></RequireAuth>} />
          <Route path="/courses/:courseID" element={<RequireAuth><MainLayout><CoursesManagementPage /></MainLayout></RequireAuth>} />
          
          <Route path="/certification" element={<RequireAuth><MainLayout><CertificationPage /></MainLayout></RequireAuth>} />
          <Route path="/reports" element={<RequireAuth><MainLayout><ReportsPage /></MainLayout></RequireAuth>} />
          <Route path="/change-password" element={<RequireAuth><MainLayout><ChangePasswordPage /></MainLayout></RequireAuth>} />
          <Route path="/manage-profile" element={<RequireAuth><MainLayout><ProfileManagerPage /></MainLayout></RequireAuth>} />
          <Route path="/invoices" element={<RequireAuth><MainLayout><UserInvoicesPage /></MainLayout></RequireAuth>} />
          <Route path="/history" element={<RequireAuth><MainLayout><UserHistoriesPage /></MainLayout></RequireAuth>} />
          <Route path="/purchase-plan" element={<RequireAuth><MainLayout><PurchasePlanPage /></MainLayout></RequireAuth>} />
      {/* User Routes */}

      {/* Reports Routes */}
      <Route path="/all-frameworks-report" element={<RequireAuth><ReportLayout><AllFrameworksReport /></ReportLayout></RequireAuth>} />
      <Route path="/single-framework-report" element={<RequireAuth><ReportLayout><SingleFrameworkReport /></ReportLayout></RequireAuth>} />
      <Route path="/all-certificates-report" element={<RequireAuth><ReportLayout><AllCertificatesReport /></ReportLayout></RequireAuth>} />
      <Route path="/all-Programs-report" element={<RequireAuth><ReportLayout><AllProgramsReport /></ReportLayout></RequireAuth>} />
      <Route path="/all-Courses-report" element={<RequireAuth><ReportLayout><AllCoursesReport /></ReportLayout></RequireAuth>} />
      <Route path="/all-courses-prerequisites-report" element={<RequireAuth><ReportLayout><CoursePrerequisiteReport /></ReportLayout></RequireAuth>} />
      {/* Reports Routes */}
      
      <Route path="*" element={<MainLayout><NotFoundPage /></MainLayout>} />
      
    </Routes>
  )
}

import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useCertificate } from '../context/CertificateContext';
import { useFramework } from '../context/FrameworkContext';
import { useCourse } from '../context/CourseContext';
import { useProgram } from '../context/ProgramContext';
import { useSettings } from '../context/SettingContext';


export default function CertificateEditor(props) {
  const { selectedCertificate, setSelectedCertificate } = useCertificate();
  const { userFrameworks, getFrameworks } = useFramework();
  const { userCourses, getUserCourses } = useCourse();
  const { userPrograms, getUserPrograms } = useProgram();
  const { settingData, getSettingByName } = useSettings();

  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!settingData?.academicLevels) await getSettingByName('academicLevels');
      await getFrameworks();
      await getUserCourses();
      await getUserPrograms();
      if (!settingData?.levels) await getSettingByName('levels');
      
    };
    fetchAllData();
  }, [  getSettingByName, settingData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    let updatedCertificate = { ...selectedCertificate };
    let schema = updatedCertificate;
    const path = event.target.name;
    const nestedItemsList = path.split('.');
    const nestedLevels = nestedItemsList.length;

    for (let i = 0; i < nestedLevels - 1; i++) {
      const element = nestedItemsList[i];
      if (!schema[element]) schema[element] = {};
      schema = schema[element];
    }
    schema[nestedItemsList[nestedLevels - 1]] = value;
    setSelectedCertificate({ ...updatedCertificate });
  };

  const handleChangeAcademicLevel = (event) => {
    let updatedCertificate={...selectedCertificate};
    let valueFromSelection=String(event.target.value)
    updatedCertificate.academicLevel=valueFromSelection;
    setSelectedCertificate({...updatedCertificate})
  };

  const handleCertificateForChange = (e) => {
    const selectedValue = e.target.value;
    handleInputChange(e);

    switch (selectedValue) {
      case 'Framework':
        setOptionsList(userFrameworks?.map((fw) => ({ id: fw._id, name: fw.frameworkName })));
        break;
      case 'Course':
        setOptionsList(userCourses?.map((course) => ({ id: course._id, name: course.name })));
        break;
      case 'Program':
        setOptionsList(userPrograms?.map((program) => ({ id: program._id, name: program.name })));
        break;
      case 'Level':
        setOptionsList(settingData?.levels.map((level) => ({ id: level._id, name: level.name })));
        break;
      default:
        setOptionsList([]);
    }
  };

  if (!selectedCertificate) {
    return (
      <Row>
        <Col>No certificate selected</Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label >Certificate Academic Level</Form.Label>
        </Col>
        <Col>
          <Form.Select
          className="formSelect"
          as="select"
          name="academicLevel"
            defaultValue={selectedCertificate.academicLevel || ''}
              onChange={handleChangeAcademicLevel}
          >
            <option value="">Select Certificate Level</option>
            {settingData?.academicLevels?.map((item, index) => (
              <option
                key={index}
                value={item.name+'|'+item.description}
              >
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Certificate Title</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="title"
            type="text"
            value={selectedCertificate?.title || ''}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Issued By</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="issuedBy"
            type="text"
            value={selectedCertificate?.issuedBy || ''}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Certificate for</Form.Label>
        </Col>
        <Col>
          <Form.Control
          className='formSelect'
            as="select"
            name="certificateFor"
            value={selectedCertificate?.certificateFor || 'Framework' }
            onChange={handleCertificateForChange}
          >
            <option value="">Please select</option>
            <option value="Framework">Framework</option>
            <option value="Program">Program</option>
            <option value="Course">Course</option>
            <option value="Level">Level</option>
          </Form.Control>
        </Col>
      </Row>

      {optionsList.length > 0 && (
        <Row>
          <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
            <Form.Label>Select {selectedCertificate?.certificateFor}</Form.Label>
          </Col>
          <Col>
            <Form.Select
              className="formSelect"
              name="selectedID"
              value={selectedCertificate?.selectedID || optionsList[0]?.id || ''}
              onChange={handleInputChange}
            >
              {optionsList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Achievement Description</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="acheivementDescription"
            type="text"
            value={selectedCertificate?.acheivementDescription || ''}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Validity Duration</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="validityDuration"
            type="text"
            value={selectedCertificate?.validityDuration || ''}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
    </>
  );
}
